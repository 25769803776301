import { render, staticRenderFns } from "./LayoutContentHorizontalNav.vue?vue&type=template&id=2c0bedc1&scoped=true&"
import script from "./LayoutContentHorizontalNav.vue?vue&type=script&lang=js&"
export * from "./LayoutContentHorizontalNav.vue?vue&type=script&lang=js&"
import style0 from "./LayoutContentHorizontalNav.vue?vue&type=style&index=0&id=2c0bedc1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0bedc1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VApp,VAppBar,VCol,VFooter,VMain,VOverlay,VSystemBar})
